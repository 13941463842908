import { ResetCSS } from '@thenexlabs/uikit'
import Script from 'next/script'
import BigNumber from 'bignumber.js'
import EasterEgg from 'components/EasterEgg'
import GlobalCheckClaimStatus from 'components/GlobalCheckClaimStatus'
import SubgraphHealthIndicator from 'components/SubgraphHealthIndicator'
import { ToastListener } from 'contexts/ToastsContext'
import useEagerConnect from 'hooks/useEagerConnect'
import { useInactiveListener } from 'hooks/useInactiveListener'
import useSentryUser from 'hooks/useSentryUser'
import useUserAgent from 'hooks/useUserAgent'
import type { AppProps } from 'next/app'
import Head from 'next/head'
import React, { Fragment, useEffect, useState } from 'react'
import { useRouter } from 'next/router';
import Loading from 'views/Loading';
import { PersistGate } from 'redux-persist/integration/react'
import { useStore, persistor } from 'state'
import { usePollBlockNumber } from 'state/block/hooks'
import { usePollCoreFarmData } from 'state/farms/hooks'
import { NextPage } from 'next'
import { useFetchProfile } from 'state/profile/hooks'
import { useXp } from 'state/xp/hooks'
import { useGLITCH } from 'state/glitch/hooks'
import { useNexItems } from 'state/nexItems/hooks'
import { useCoreManager } from 'state/coreManager/hooks'
import { useDataSticks } from 'state/dataSticks/hooks'
import { useAutoBossBattles } from 'state/autoBossBattles/hooks'
import { Blocklist, Updaters } from '..'
import ErrorBoundary from '../components/ErrorBoundary'
import Menu from '../components/Menu'
import Providers from '../Providers'
import GlobalStyle from '../style/Global'
// import dynamic from 'next/dynamic';
// const displayPic = '/Nica.png';

// const Widget = dynamic(
//   () =>
//     import('react-chat-widget').then(
//       (mod) => ((mod as unknown) as { Widget: React.ComponentType<any> }).Widget
//     ),
//   { ssr: false }
// );
// import 'react-chat-widget/lib/styles.css';

// let addResponseMessage: (message: string) => void;

// import('react-chat-widget')
//   .then((mod) => {
//     addResponseMessage = ((mod as unknown) as { addResponseMessage: (message: string) => void })
//       .addResponseMessage;
//   },
//     { ssr: false }
//   )
//   .catch((err) => {
//     console.error(err);
//   });



// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

function GlobalHooks() {
  usePollBlockNumber()
  useEagerConnect()
  useFetchProfile()
  usePollCoreFarmData()
  useUserAgent()
  useInactiveListener()
  useSentryUser()
  useXp()
  useNexItems()
  useDataSticks()
  useCoreManager()
  useAutoBossBattles()
  useGLITCH()
  return null
}

function MyApp(props: AppProps<{ initialReduxState: any }>) {
  const { pageProps, Component } = props
  const store = useStore(pageProps.initialReduxState)

  // const userToken = localStorage.getItem('jwtToken');
  
  // if(userToken){
  //   // Decode JWT token to get expiration time
  //   const decodedToken = jwt.decode(userToken);
  //   let user = decodedToken
  //   const expirationTime = user.exp * 1000;
  //   // // Check if token is expired
  //   if (Date.now() >= expirationTime) {
  //     // Token is expired, handle accordingly
  //     localStorage.removeItem('jwtToken');
  //     user = null
  //   }
  //   props.user = user
  // }

  return (
    <>
      <Head>
        
        <meta name="description" content="NEX Labs - Cyber Security + AI, Web4 Technologies: Protect your data with our automated cybersecurity product. Subscribe now for optimal security." />
        <meta name="keywords" content="web4, web3, ai, cybersecurity, cyber, security, services, automated, automate, product, subscription, data, protection" />
        <meta name="theme-color" content="#39eb13" />
        <meta name="twitter:image" content="https://thenex.world/images/hero.png" />
        <meta
          name="twitter:description"
          content="NEX Labs - Cyber Security + AI, Web4 Technologies"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="NEX Labs - The NEX - Cyber Security AI, Web4 R&D, WebXR World Hub"
        />
        <meta name="google-adsense-account" content="ca-pub-5868803777621110"/>
        <title>NEX Labs | The NEX</title>
      </Head>
      <Providers store={store}>
        <Blocklist>
          <GlobalHooks />
          <Updaters />
          <ResetCSS />
          <GlobalStyle />
          <GlobalCheckClaimStatus excludeLocations={[]} />
          <PersistGate loading={null} persistor={persistor}>
            <App {...props} />
          </PersistGate>
        </Blocklist>
      </Providers>
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `,
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // const [showChat, setShowChat] = useState(false);
  const router = useRouter();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // cursor art ////////////////////////////////////////////////
    // const cursor = document.getElementById('cursor-diamond');
    // const cursor2 = document.getElementById('cursor-dot');
    // const cursor3 = document.getElementById('cursor-circle');
    // const cursor4 = document.getElementById('cursor-square');

    // document.addEventListener('mousemove', (e) => {
    //   if(e.pageX < window.innerWidth - 15 ){
    //     cursor.style.left = e.pageX - 5 + 'px';
    //     cursor.style.top = e.pageY - 5 + 'px';
    //     cursor2.style.left = e.pageX - 5 + 'px';
    //     cursor2.style.top = e.pageY - 5 + 'px';
    //     cursor3.style.left = e.pageX - 5 + 'px';
    //     cursor3.style.top = e.pageY - 5 + 'px';
    //     // cursor4.style.left = e.pageX - 5 + 'px';
    //     // cursor4.style.top = e.pageY - 5 + 'px';
    //   }
    // });

    // document.addEventListener('mouseenter', () => {
    //     cursor.style.display = 'block';
    //     cursor2.style.display = 'block';
    //     cursor3.style.display = 'block';
    //     // cursor4.style.display = 'block';
    // });

    // document.addEventListener('mouseleave', () => {
    //     cursor.style.display = 'none';
    //     cursor2.style.display = 'none';
    //     cursor3.style.display = 'none';
    //     // cursor4.style.display = 'none';
    // });

    // chabot widget ////////////////////////////////////////
  //   let counter = 0;
  //   let intervalId;
  //   const fetchData = async () => {
  //     const response = await fetch('https://flask-app-adpn.onrender.com/init', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({ api_key: process.env.NEXT_PUBLIC_OPENAI_API_KEY })
  //     });
  //     const data = await response.text();
  //     if (data == 'OK' && addResponseMessage) {
  //       setShowChat(true);
  //       if (addResponseMessage) {
  //         addResponseMessage('Hey my name is **Nix**! I\'m an AI built to help you navigate The NEX, I\'m still learning but is there anything I can help you with today ?');
  //       }
  //       intervalId = setInterval(fetchData, 15 * 60 * 1000);
  //     } else {
  //       if (counter < 2) {
  //         counter++;
  //         fetchData();
  //       }
  //     }
  //   }
  //   fetchData();
  //   return () => clearInterval(intervalId);

    // loading page /////////////////////////////////////////////
    const handleStart = () => setLoading(true);
    const handleComplete = () => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  setTimeout(function() {
    setLoading(false);
  }, 3500);

  // const handleNewUserMessage = async (newMessage) => {
  //   // console.log(`New message incoming! ${newMessage}`);
  //   // Now send the message through the backend API
  //   const response = await fetch('https://flask-app-adpn.onrender.com/llm', {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify({ query: newMessage })
  //   });
  //   const data = await response.json();
  //   if(data.response){
  //     if(addResponseMessage){
  //       addResponseMessage(data.response); 
  //     }
  //   }
  // };

  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment
  return (
    <ErrorBoundary>
      {loading ? 
        <Loading /> 
        :
        <Menu >
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </Menu>
      }
      <EasterEgg iterations={2} />
      <ToastListener />
      <SubgraphHealthIndicator />
      <div id="cursor-dot"></div>
      <div id="cursor-diamond"></div>
      <div id="cursor-circle"></div>
      
      {/*
      <div id="cursor-square"></div>
      */}
      {/*showChat && 
        <Widget
          handleNewUserMessage={handleNewUserMessage}
          profileAvatar={displayPic}
          titleAvatar={displayPic}
          emojis={true}
          title="Nix"
          subtitle="Hi (｡◕‿‿◕｡)"
        />
      */}
    </ErrorBoundary>
  )
}

export default MyApp
