import { MenuItemsType, DropdownMenuItemType } from '@thenexlabs/uikit'
import { ContextApi } from 'contexts/Localization/types'
import { nftsBaseUrl } from 'views/Nft/market/constants'

export type ConfigMenuItemsType = MenuItemsType & { hideSubNav?: boolean }

const config: (t: ContextApi['t']) => ConfigMenuItemsType[] = (t) => [
  {
    label: t('NixGuard'),
    icon: 'Verified',
    href: '/security/services',
    // showItemsOnMobile: false,
    items: [
      {
        label: t('App'),
        href: 'https://nixguard.thenex.world'//'/nix',
      },
      {
        label: t('Subscribe'),
        href: '/security/subscribe',
      },
      {
        label: t('TOS'),
        href: '/nix/terms-of-service',
      },
      {
        label: t('Privacy Policy'),
        href: '/nix/privacy-policy',
      },
      // {
      //   label: t('Security Services'),
      //   href: '/security/services',
      // },
      // {
      //   label: t('Join Beta'),
      //   href: 'https://d5mlsmm4ujx.typeform.com/to/b0U2avC8'//'/contact',
      // },
    ],
  },
  {
    label: t('Play'),
    icon: 'Prize',
    href: '/experiences',
    // showItemsOnMobile: false,
    items: [
      {
        label: t('The NEX World Hub'),
        href: '/hub',
      },
      {
        label: t('Auto Battles'),
        href: '/autobattles',
      },
      {
        label: t('Discover Experiences'),
        href: '/experiences',
      },
    ],
  },
  {
    label: t('About'),
    icon: 'Account',//icon: 'Earn',
    href: '/about-nex',
    items: [
      {
        label: t('Our Team'),
        href: '/about-nex',
      },
      {
        label: t('NixGuard'),
        href: '/nix/about',
      },
      {
        label: t('NEX Level Gaming'),
        href: 'https://hub.thenex.world/about',
      },
      /*{
        label: t('Farms'),
        href: '/farms',
      },*/
    ],
  },
  {
    label: t('Web4'),
    icon: 'Logo',
    href: '/web4/about',
    items: [
      {
        label: t('What\'s Web4?'),
        href: '/web4/about',
      },
      {
        label: t('Web4 Network Research'),
        href: '/documents/The_NEX_Network_Whitepaper.pdf',
      },
    ],
  },
  // {
  //   label: t('Trade'),
  //   icon: 'Swap',//icon: 'Earn',
  //   href: '/swap',
  //   items: [
  //     {
  //       label: t('Exchange'),
  //       href: '/swap',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //     /*{
  //       label: t('Farms'),
  //       href: '/farms',
  //     },*/
  //   ],
  // },
  // {
  //   label: t('Earn'),
  //   icon: 'Earn',
  //   href: '/farms',
  //   showItemsOnMobile: false,
  //   items: [
  //     {
  //       label: t('Farms'),
  //       href: '/farms',
  //     },
  //     // {
  //     //   label: t('Loot Boxes'),
  //     //   href: '/lootblocks',
  //     // },
  //     /*{
  //       label: t('IGOs'),
  //       href: '/igo',
  //     },*/
  //   ],
  // },
  // {
  //   label: t('Assets'),
  //   icon: 'Nft',
  //   href: '/glitch',
  //   items: [
  //     {
  //       label: t('GLITCH'),
  //       href: '/glitch',
  //     },
  //     {
  //       label: t('Cores'),
  //       href: '/core',
  //     },
  //     {
  //       label: t('Keycards'),
  //       href: '/keycard',
  //     },
  //   ],
  // },/*
  // {
  //   label: t('Games'),
  //   icon: 'Nft',
  //   href: '/games',
  //   items: [
  //     {
  //       label: t('Games'),
  //       href: '/games',
  //     },
  //     {
  //       label: t('Studios'),
  //       href: '/studios',
  //     },
  //   ],
  // },
  /* {
    label: t('Win'),
    icon: 'Trophy',
    href: '/prediction',
    items: [
      {
        label: t('Trading Competition'),
        href: '/competition',
      },
      {
        label: t('Prediction (BETA)'),
        href: '/prediction',
      },
      {
        label: t('Lottery'),
        href: '/lottery',
      },
    ],
  },
  {
    label: t('NFT'),
    icon: 'Nft',
    href: `${nftsBaseUrl}`,
    items: [
      {
        label: t('Overview'),
        href: `${nftsBaseUrl}`,
      },
      {
        label: t('Collections'),
        href: `${nftsBaseUrl}/collections`,
      },
      {
        label: t('Activity'),
        href: `${nftsBaseUrl}/activity`,
      },
    ],
  },
  {
    label: '',
    icon: 'More',
    href: '/info',
    hideSubNav: true,
    items: [
      {
        label: t('Info'),
        href: '/info',
      },
      {
        label: t('IFO'),
        href: '/ifo',
      },
      {
        label: t('Voting'),
        href: '/voting',
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('Leaderboard'),
        href: '/teams',
      },
      {
        type: DropdownMenuItemType.DIVIDER,
      },
      {
        label: t('Blog'),
        href: 'https://medium.com/pancakeswap',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
      {
        label: t('Docs'),
        href: 'https://docs.thenex.world',
        type: DropdownMenuItemType.EXTERNAL_LINK,
      },
    ],
  }, */
]

export default config
